.main-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    height: 58px;
    flex-shrink: 0;
    .header-menu {
     margin-left: 150px;
     @media screen and (max-width: 1055px) {
      margin: auto;
     }
     a {
      padding: 20px 24px;
     }
    }
   }