html{
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family:'Helvetica', sans-serif;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
