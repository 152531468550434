
.avatar .profile {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
  }
  
.avatar-ligth-selector{
  display: flex;
  justify-content: center;
}

.avatar .profile img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
.avatar .avatar-menu {
    position: absolute;
    top: 70px;
    right: 20px;
    padding: 10px 20px;
    background: #fff;
    width: 200px;
    box-sizing: 0 5px 25px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    transition: 0.5s;
    visibility: hidden;
    opacity: 0;
  }
  
.avatar .avatar-menu.active {
    top: 75px;
    visibility: visible;
    opacity: 1;
  }
  
.avatar .avatar-menu::before {
    content: "";
    position: absolute;
    top: -5px;
    right: 30px;
    width: 20px;
    height: 20px;
    background: #fff;
    transform: rotate(45deg);
  }
  
.avatar .avatar-menu h3 {
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: #555;
    line-height: 1.5em;
  }
  
.avatar .avatar-menu h3 span {
    font-size: 14px;
    color: #cecece;
    font-weight: 300;
  }
  
.login-profile-item {
    list-style: none;
    padding: 16px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
  }
  
.login-profile-item img {
    max-width: 20px;
    margin-right: 10px;
    opacity: 0.5;
    transition: 0.5s;
  }
  
.login-profile-item:hover img {
    opacity: 1;
  }
  
.login-profile-item a {
    display: inline-block;
    text-decoration: none;
    color: #555;
    font-weight: 500;
    transition: 0.5s;
  }
  
.login-profile-item:hover a {
    color: orange;
  }
  