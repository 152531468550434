.side-wrapper + .side-wrapper {
    margin-top: 20px;
   }
   
   .side-title {
    color: var(--inactive-color);
    margin-bottom: 14px;
   }
   
   .side-menu {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    a {
     text-decoration: none;
     color: var(--theme-color);
     display: flex;
     align-items: center;
     font-weight: 400;
     padding: 10px;
     font-size: 14px;
     border-radius: 6px;
     transition: 0.3s;
     &:hover {
      background-color: var(--hover-menu-bg);
     }
    }
    svg {
     width: 16px;
     margin-right: 8px;
    }
   }