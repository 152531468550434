.login-background-img{  
  background-image: url("./../assets/imgs/farm.jpg" );
  background-repeat: no-repeat; 
     -webkit-background-size: cover;
     -moz-background-size: cover;
     -o-background-size: cover;
     background-size: cover;
}

.login-form-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.form-login {
    position: relative;
    display: flex;
    width: 70%;
    flex-direction: column;
    gap: 10px;
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: 0.4em;
    background: rgba(255, 255, 255, 0.2);;
    border-radius: 25px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    transition: .4s ease-in-out;
  }
  
  .form-login:hover {
    transform: scale(1.05);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
  
  #heading-login {
    text-align: center;
    /* margin: 2em; */
    color: rgb(255, 255, 255);
    font-size: 1.2em;
  }

  #heading-login img{
    width: 100%;
  }
  
  .login-field {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
    border-radius: 25px;
    padding: 0.6em;
    border: none;
    outline: none;
    color: white;
    background-color: #171717;
    box-shadow: inset 2px 5px 10px rgb(5, 5, 5);
  }
  
  .input-icon {
    height: 1.3em;
    width: 1.3em;
    fill: white;
    
  }

  .login-input-field {
    background: none;
    border: none;
    outline: none;
    width: 100%;
    color: #d3d3d3;
    width: 100%;
  }
  
  .form-login .btn-login {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-top: 2.5em;
  }

  .btn-login button {
    width: 100%;
    white-space: nowrap;
    padding: 0.6rem;
  }

  .button1-login {
    padding: 0.5em;
    padding-left: 1.1em;
    padding-right: 1.1em;
    border-radius: 5px;
    margin-right: 0.5em;
    border: none;
    outline: none;
    transition: .4s ease-in-out;
    background-color: #252525;
    color: white;
  }
  
  .button1-login:hover {
    background-color: black;
    color: white;
  }
  
  .button2-login {
    padding: 0.5em;
    padding-left: 2.3em;
    padding-right: 2.3em;
    border-radius: 5px;
    border: none;
    outline: none;
    transition: .4s ease-in-out;
    background-color: #252525;
    color: white;
  }
  
  .button2-login:hover {
    background-color: black;
    color: white;
  }
  
  .button3-login {
    margin-bottom: 3em;
    border-radius: 5px;
    border: none;
    outline: none;
    transition: .4s ease-in-out;
    background-color: red;
    color: white;
    padding: 0.6rem;
  }
  
  .button3-login:hover {
    background-color: red;
    color: white;
  }

  .login-input-field:focus {
    font-size: 16px;
  }

  @media (min-width: 600px) {
    .form-login {
      width: 20%;
    }
    
  }